body{
  font-family: 'Roboto'
}

.left-lines {
    position: absolute;
    left: 3vw;
    top: 66vh;
    z-index: -1;
}

.right-lines {
  position: absolute;
    left: 85vw;
    top: 240vh;
    z-index: -1;
}

.path {
  stroke-dasharray: 300;
  stroke-dashoffset: 1000;
  animation: dash 18s linear alternate infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 822;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.main {
  margin:auto;
  width: 64vw;
  max-width: 100%;
}

.top-spacer{
  height: 25vh;
}

.middle-spacer{
  height: 33vh;
}

.title{
  font-family: 'Merriweather';
  font-size: 8vw;
}

.bio{
  height: 100vh;
  margin-bottom: 1rem;
}

.bio-text{
grid-area: bio-text;
font-size:4vmin;
}

.content{
  width: 100%;
}

.icon-img{
  margin:1rem;
  width: 6vw; 
  object-fit: contain;
}

.item{
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-container{
  max-width: 64vw;
  display: grid;
  grid-template-columns: 3fr 5fr 3fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
  "left top  right"
  "left center right"
  "left bottom right"
}

.contact-img{
  grid-area: center;
  border-radius: 9vh 9vh 0vh 0vh;
  width: 16vw; 
  border: 5px solid black;
  margin: 4vw auto;
}

.open-bracket{
  margin: auto;
  max-width: 8vw;
  grid-area: left;
  font-size: 30vw;
  transform: scale(1, 1.7);
}

.close-bracket{
  margin: auto;
  max-width: 8vw;
  grid-area: right;
 font-size: 30vw;
 transform: scale(1, 1.7);
 text-align: center;
}

.links-container{
  margin: 1vw;
  max-width: 32vw;
  grid-area: bottom;
}

.projectsContainer {
  padding-bottom: 5rem;
  margin: auto;
  display: grid;
  grid-gap: 1rem;
 grid-template-areas:
 "project0" 
  "project1" 
  "project2";
}

.project0 {
  grid-area: project0;
}

.project1 {
  grid-area: project1;
}

.project2 {
  grid-area: project2;
}

.card{
  margin: 2rem 2rem;
}

.card-text{
  font-size: 2.75vmax;
  margin: 1vw 0vw;
}

.card-header{
  font-family: 'Merriweather';
  font-weight: bold;
  font-size: 4vw;
  margin-bottom: 1vw;
}

.card-img{ 
  width: 100%; 
  object-fit: contain;
}

.card-link, .card-link:visited {
  color: inherit;
  text-decoration: none;
}

.card-link:hover {
  color: dodgerblue;
  cursor: pointer;
}